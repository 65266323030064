:root {
  /* PRIMARIES */
  --primary-teal: #1380B1;
  --tangerine: #f06721;
  --primary-black: #000;
  --white: #fff;
  --pale-gray: #f7f7f7;

  /* GRAYS */
  --nuetral-1:#f6f6f6;
  --nuetral-2:#dfdfdf;
  --nuetral-3:#b2b2b2;
  --nuetral-4:#707070;
  --nuetral-5:#4a4a4a;
  --nuetral-6:#2d2d2d;

  /* ALERTS */
  --success:#00AA00;
  --error:#E50000;
  --caution:#FFD041;

  --CP-tangerine: #f06721;
  --CP-blue: #1380b1;
  --highlight-blue: #cee4f1;
  --highlight-yellow: #ffedcc;
  --highlight-orange: #f8daca;
  --reddish-orange: var(--CP-tangerine);
  --greyish-brown: #4a4a4a;
  --very-light-grey: #dfdfdf;
  --black-19: rgba(0, 0, 0, 0.19);
  --battleship-grey: #b2b2b2;
  --pale-grey: #f6f7f9;
  --pale-grey-two: #f1f4f7;
  --black: #000000;
  --dark-blue: #1380b1;
  --medium-blue: #ddecf4;
  --light-blue: #dfeef7;
  --paper-grey: #fdfdfd;
  --very-light-blue: #f4fbff;
}

/* Text styles */
body {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
h1 {
  font-size: 21px;
  font-weight: bold;
  color: var(--black);
}
h2 {
  font-size: 18px;
  font-weight: bold;
  color: var(--black);
}
h3 {
  font-size: 16px;
  color: var(--greyish-brown);
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: black;
  background-color: var(--highlight-orange);
}
a:focus,
a:visited,
a:link,
a:active {
  text-decoration: none;
}
.chatlio-widget a:hover {
  color: initial;
  background-color: initial;
}
.offices .active {
  background-color: var(--highlight-yellow);
}
.selectable:hover {
  cursor: pointer;
}
.larger {
  font-size: 120%;
}
.smaller {
  font-size: 80%;
}
.checkoutError {
  background-color: #ffecec;
  margin: .5rem 0;
  border: 1px solid red;
  padding: 0.25rem 1rem;
  text-align: center;
}
.checkoutError h1 {
  font-size: 32px;
  padding: 0;
  margin: 0.5rem 0 0 0;
  font-variant: all-caps;
  color: red;
  font-weight: 900;
}
.checkoutError h2 {
  color: red;
  font-size: 24px;
  font-variant: small-caps;
  padding: 0;
  margin: 0.25rem 0 0 0;
}
.checkoutError p {
  font-style: italic;
  color: black;
}

/**********************************************************************
These styles replace a lot of redundant syled-components definitions 
redundantly cloned across multiple pages
**********************************************************************/
   
.content {
  font-size: 14px;
  margin-top: 0px;
  margin-left: 40px;
  margin-right: 20px;
}

.infoPage {
  display: grid;
  margin: 0;
  grid-template-columns: 312px auto;
  gap: 2rem;
  max-width: 1200px;
}
.infoPage .page {
  margin-left: 300px;
  float: left;
  height: max(100%, calc(100vh - 78px));
  overflow: auto;
  background-color: white;
}
.fearless {
  max-width: 40%;
  float: right;
  margin-left: 20px;
  margin-bottom: 12px;
  box-shadow: 10px 10px 10px 2px rgb(167, 165, 165);
  border-radius: 12px;
  object-fit: scale-down;
}
.infoPage h1 {
  font-size: 24px;
}
.infoPage p {
  max-width: 40rem;
  font-size: 16px;
  line-height: 125%;
}
.infoPage .faq h2 ~ p {
  margin-left: 1rem;
}
.infoPage li {
  max-width: 40rem;
}
.infoPage .toc {
  position: fixed;
  width: 280px;
  height: max(100%, calc(100vh - 78px));
  background-color: var(--light-blue);
  margin: -6px 20px 0 4px;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0px 0 0px;
}
.toc ul {
  list-style-type: none; /* Remove bullets */
  padding: 16px 0 0 0;
  margin: 0; /* Remove margins */
}
.toc li {
  margin: 4px 0 12px 0 ;
  padding: 4px 0;
}
.toc li.selected {
  background-color: var(--CP-blue);
  color: white;
  font-size: 125%;
}
.toc li.selected a {
  color: white;
}
.toc li.selected a:hover {
  background-color: var(--CP-blue);
  color: white;
  cursor: auto;
}

.toc a {
  color: black;
  padding: 0 12px;
}
.faqEntry {
  margin: 1rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  max-width: 40rem;
}
.faqEntry.box {
  padding: 1rem;
  border: 1px solid #c0c0c0;
}
.faqEntry.open .answer {
  overflow: hidden;
  max-height: 10000px;
  transition: max-height 1s ease-in-out;
}
.faqEntry.closed .answer {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease;
}
.faqEntry .question {
  font-size: 16px;
  max-width: 40rem;
}
.faqEntry.open .question {
  cursor: pointer;
  padding-bottom: 4px;
  border-bottom: 1px solid #808080;
  font-weight: bold;
}
.faqEntry.closed .question {
  font-weight: normal;
  cursor: pointer;
}
.toc fieldset {
  border: 1px solid #b8b6b6;
  margin: 4px 8px;
  padding: 0 0 0 8px;
}
.toc legend {
  font-weight: normal;
}

.menu {
  position: fixed;
  margin: 0;
  padding: 0;
  text-align: right;
  z-index: 10;
  top: 58px;
  right: -4px;
  opacity: 1;
  min-width: 150px;
  background-color: var(--dark-blue);
  color: white;
  border-radius: 0 0 0 12px;
  transition: 0.5s ease-in-out;
  max-height: 1000px;
  font-size: 18px;
  /* box-shadow: -8px 10px 14px -2px rgb(167, 165, 165); */
}
.menu.hide {
  transform: translateY(-250%);
  -webkit-transform: translateY(-250%);
}
.menu ul {
  margin: 0;
  padding: 4px 16px 12px 20px;
  list-style: none;
}
.menu li {
  padding: 4px 12px;
}
.menu .selectable:hover {
  color: white;
  font-weight: bold;
  background-color: var(--reddish-orange);
  border-radius: 12px;
}
.menu a:hover {
  color: unset !important;
  background-color: unset !important;
}
.contactMethods {
  margin-left: 20px;
  display: grid;
  grid-template-columns: 80px auto;
  gap: 0.5rem;
  font-size: 16px;
}
.contactMethods .label {
  font-style: italic;
}
.contactMethods .value {
  font-weight: bold;
}
.acctforms li {
  padding: 0px 0px 4px 0px;
}
.acctforms h2 {
  margin-bottom: 24px;
}
.acctforms img {
  position: relative;
  top: 16px;
  margin-right: 8px;
}


.impersonate__tooltip{
  border: 1px solid rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 21px;
}
.impersonate__cancel-card-wrapper{
  background-color: white;
  color: #222;
  height: 100%;
  width: 100%;
}
.impersonate__error{
  color: red;
}
.impersonate__cancel-icon {
  height: 26px;
  float: right;
  z-index: 10;
  margin-top: -6px;
  margin-left: 4px;
}
.impersonate__cancel-card-wrapper{
  background-color: white;
  height: 100%;
  width: 100%;
}
.impersonate__cancel-btn{
  margin-top: 3px;
  margin-left: 15%;
  height: 30px;
  width: 70%;
  color:white;
  background-color: #F06721;
  border:none;
  border-radius: 3px;
  transition: background-color .2s ease-in;
  cursor: pointer;
}
.impersonate__cancel-btn:hover{
  background-color: #f8daca71;
  color:black;
}
.impersonate__info>div{
  overflow-wrap: break-word;
  word-break:keep-all;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-align: start;
  font-size: 16px;
}
.impersonate__info>div>img{
  margin-right: 5px;
}

.login__password-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.login__forgotpw {
  font-size: .8rem;
}
.login__password-field{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 380px;
  min-width: 380px;
}
.login__password-field>input{
  box-sizing: border-box;
  margin-right: 0px !important;
  min-width: 380px;
  width: 380px;
}
.login__password-field>span{
  position: absolute;
  right: 8px;
  top: 3px;
  height: 28px;
  width: 28px;
}
.login__error {
  max-width: 100%;
  border: 1px solid red;
  border-radius: 5px;
  background-color: #fae7e7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  padding: 12px;
  margin-bottom: 16px;
  font-size: 14px;
  height: fit-content !important;
}

.login__error>*{
  margin: 0;
}
.login__inactivated-error>*{
  margin-bottom: 8px;
  margin-top: 0px;
}
.login__resend-btn{
  border:none;
  background-color: transparent !important;
  color:rgb(40, 103, 175) !important;
  box-shadow: none !important;
  width: fit-content !important;
  height: fit-content !important;
  margin:0;
  padding:0;
  font-size: 14px !important;
  cursor: pointer;
}
.text-input{
  box-sizing: border-box;
}


/* derived from orderlink .header__account-container */
.header__account-container{
  color:white;
  font-size: .95rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  margin-left: 12px;
  padding: 0px 4px 2px 8px !important;
  transition: background-color .3s linear;
  border-radius: 4px;
  cursor: pointer;
  float:right;
}

.header__account-container:hover{
  background-color: #114c68;
  /* background-color: #4F8EAC; */
}

span.chatlio-online-dot {
  background-color: #00aa00;
}