.nat-acct__hq{
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50%;
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  background-color: #B87717;
  float:right;
  margin-top: 14px;
}

.nat-acct__hq>img{
  height: 75%;
  width: 75%;
}

.nat-acct__branch{
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50%;
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;
  background-color: #6FAEB7;
  float:right;
  margin-top: 14px;
}

.nat-acct__branch>img{
  height: 65%;
  width: 65%;
}