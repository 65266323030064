@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  /* PRIMARIES */
  --primary-teal: #1380B1;
  --tangerine: #f06721;
  --primary-black: #000;
  --white: #fff;
  --pale-gray: #f7f7f7;

  /* GRAYS */
  --nuetral-1:#f6f6f6;
  --nuetral-2:#dfdfdf;
  --nuetral-3:#b2b2b2;
  --nuetral-4:#707070;
  --nuetral-5:#4a4a4a;
  --nuetral-6:#2d2d2d;

  /* ALERTS */
  --success:#00AA00;
  --error:#E50000;
  --caution:#FFD041;

  --CP-tangerine: #f06721;
  --CP-blue: #1380b1;
  --highlight-blue: #cee4f1;
  --highlight-yellow: #ffedcc;
  --highlight-orange: #f8daca;
  --reddish-orange: var(--CP-tangerine);
  --greyish-brown: #4a4a4a;
  --very-light-grey: #dfdfdf;
  --black-19: rgba(0, 0, 0, 0.19);
  --battleship-grey: #b2b2b2;
  --pale-grey: #f6f7f9;
  --pale-grey-two: #f1f4f7;
  --black: #000000;
  --dark-blue: #1380b1;
  --medium-blue: #ddecf4;
  --light-blue: #dfeef7;
  --paper-grey: #fdfdfd;
  --very-light-blue: #f4fbff;
}

/* Text styles */
body {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
h1 {
  font-size: 21px;
  font-weight: bold;
  color: #000000;
  color: var(--black);
}
h2 {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  color: var(--black);
}
h3 {
  font-size: 16px;
  color: #4a4a4a;
  color: var(--greyish-brown);
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: black;
  background-color: #f8daca;
  background-color: var(--highlight-orange);
}
a:focus,
a:visited,
a:link,
a:active {
  text-decoration: none;
}
.chatlio-widget a:hover {
  color: initial;
  background-color: initial;
}
.offices .active {
  background-color: #ffedcc;
  background-color: var(--highlight-yellow);
}
.selectable:hover {
  cursor: pointer;
}
.larger {
  font-size: 120%;
}
.smaller {
  font-size: 80%;
}
.checkoutError {
  background-color: #ffecec;
  margin: .5rem 0;
  border: 1px solid red;
  padding: 0.25rem 1rem;
  text-align: center;
}
.checkoutError h1 {
  font-size: 32px;
  padding: 0;
  margin: 0.5rem 0 0 0;
  -webkit-font-feature-settings: ;
          font-feature-settings: ;
  font-variant: all-caps;
  color: red;
  font-weight: 900;
}
.checkoutError h2 {
  color: red;
  font-size: 24px;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
  padding: 0;
  margin: 0.25rem 0 0 0;
}
.checkoutError p {
  font-style: italic;
  color: black;
}

/**********************************************************************
These styles replace a lot of redundant syled-components definitions 
redundantly cloned across multiple pages
**********************************************************************/
   
.content {
  font-size: 14px;
  margin-top: 0px;
  margin-left: 40px;
  margin-right: 20px;
}

.infoPage {
  display: grid;
  margin: 0;
  grid-template-columns: 312px auto;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 1200px;
}
.infoPage .page {
  margin-left: 300px;
  float: left;
  height: max(100%, calc(100vh - 78px));
  overflow: auto;
  background-color: white;
}
.fearless {
  max-width: 40%;
  float: right;
  margin-left: 20px;
  margin-bottom: 12px;
  box-shadow: 10px 10px 10px 2px rgb(167, 165, 165);
  border-radius: 12px;
  object-fit: scale-down;
}
.infoPage h1 {
  font-size: 24px;
}
.infoPage p {
  max-width: 40rem;
  font-size: 16px;
  line-height: 125%;
}
.infoPage .faq h2 ~ p {
  margin-left: 1rem;
}
.infoPage li {
  max-width: 40rem;
}
.infoPage .toc {
  position: fixed;
  width: 280px;
  height: max(100%, calc(100vh - 78px));
  background-color: #dfeef7;
  background-color: var(--light-blue);
  margin: -6px 20px 0 4px;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0px 0 0px;
}
.toc ul {
  list-style-type: none; /* Remove bullets */
  padding: 16px 0 0 0;
  margin: 0; /* Remove margins */
}
.toc li {
  margin: 4px 0 12px 0 ;
  padding: 4px 0;
}
.toc li.selected {
  background-color: #1380b1;
  background-color: var(--CP-blue);
  color: white;
  font-size: 125%;
}
.toc li.selected a {
  color: white;
}
.toc li.selected a:hover {
  background-color: #1380b1;
  background-color: var(--CP-blue);
  color: white;
  cursor: auto;
}

.toc a {
  color: black;
  padding: 0 12px;
}
.faqEntry {
  margin: 1rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  max-width: 40rem;
}
.faqEntry.box {
  padding: 1rem;
  border: 1px solid #c0c0c0;
}
.faqEntry.open .answer {
  overflow: hidden;
  max-height: 10000px;
  transition: max-height 1s ease-in-out;
}
.faqEntry.closed .answer {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease;
}
.faqEntry .question {
  font-size: 16px;
  max-width: 40rem;
}
.faqEntry.open .question {
  cursor: pointer;
  padding-bottom: 4px;
  border-bottom: 1px solid #808080;
  font-weight: bold;
}
.faqEntry.closed .question {
  font-weight: normal;
  cursor: pointer;
}
.toc fieldset {
  border: 1px solid #b8b6b6;
  margin: 4px 8px;
  padding: 0 0 0 8px;
}
.toc legend {
  font-weight: normal;
}

.menu {
  position: fixed;
  margin: 0;
  padding: 0;
  text-align: right;
  z-index: 10;
  top: 58px;
  right: -4px;
  opacity: 1;
  min-width: 150px;
  background-color: #1380b1;
  background-color: var(--dark-blue);
  color: white;
  border-radius: 0 0 0 12px;
  transition: 0.5s ease-in-out;
  max-height: 1000px;
  font-size: 18px;
  /* box-shadow: -8px 10px 14px -2px rgb(167, 165, 165); */
}
.menu.hide {
  transform: translateY(-250%);
  -webkit-transform: translateY(-250%);
}
.menu ul {
  margin: 0;
  padding: 4px 16px 12px 20px;
  list-style: none;
}
.menu li {
  padding: 4px 12px;
}
.menu .selectable:hover {
  color: white;
  font-weight: bold;
  background-color: #f06721;
  background-color: var(--reddish-orange);
  border-radius: 12px;
}
.menu a:hover {
  color: unset !important;
  background-color: unset !important;
}
.contactMethods {
  margin-left: 20px;
  display: grid;
  grid-template-columns: 80px auto;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-size: 16px;
}
.contactMethods .label {
  font-style: italic;
}
.contactMethods .value {
  font-weight: bold;
}
.acctforms li {
  padding: 0px 0px 4px 0px;
}
.acctforms h2 {
  margin-bottom: 24px;
}
.acctforms img {
  position: relative;
  top: 16px;
  margin-right: 8px;
}


.impersonate__tooltip{
  border: 1px solid rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 21px;
}
.impersonate__cancel-card-wrapper{
  background-color: white;
  color: #222;
  height: 100%;
  width: 100%;
}
.impersonate__error{
  color: red;
}
.impersonate__cancel-icon {
  height: 26px;
  float: right;
  z-index: 10;
  margin-top: -6px;
  margin-left: 4px;
}
.impersonate__cancel-card-wrapper{
  background-color: white;
  height: 100%;
  width: 100%;
}
.impersonate__cancel-btn{
  margin-top: 3px;
  margin-left: 15%;
  height: 30px;
  width: 70%;
  color:white;
  background-color: #F06721;
  border:none;
  border-radius: 3px;
  transition: background-color .2s ease-in;
  cursor: pointer;
}
.impersonate__cancel-btn:hover{
  background-color: #f8daca71;
  color:black;
}
.impersonate__info>div{
  overflow-wrap: break-word;
  word-break:keep-all;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-align: start;
  font-size: 16px;
}
.impersonate__info>div>img{
  margin-right: 5px;
}

.login__password-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.login__forgotpw {
  font-size: .8rem;
}
.login__password-field{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 380px;
  min-width: 380px;
}
.login__password-field>input{
  box-sizing: border-box;
  margin-right: 0px !important;
  min-width: 380px;
  width: 380px;
}
.login__password-field>span{
  position: absolute;
  right: 8px;
  top: 3px;
  height: 28px;
  width: 28px;
}
.login__error {
  max-width: 100%;
  border: 1px solid red;
  border-radius: 5px;
  background-color: #fae7e7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  padding: 12px;
  margin-bottom: 16px;
  font-size: 14px;
  height: -webkit-fit-content !important;
  height: fit-content !important;
}

.login__error>*{
  margin: 0;
}
.login__inactivated-error>*{
  margin-bottom: 8px;
  margin-top: 0px;
}
.login__resend-btn{
  border:none;
  background-color: transparent !important;
  color:rgb(40, 103, 175) !important;
  box-shadow: none !important;
  width: -webkit-fit-content !important;
  width: fit-content !important;
  height: -webkit-fit-content !important;
  height: fit-content !important;
  margin:0;
  padding:0;
  font-size: 14px !important;
  cursor: pointer;
}
.text-input{
  box-sizing: border-box;
}


/* derived from orderlink .header__account-container */
.header__account-container{
  color:white;
  font-size: .95rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  margin-left: 12px;
  padding: 0px 4px 2px 8px !important;
  transition: background-color .3s linear;
  border-radius: 4px;
  cursor: pointer;
  float:right;
}

.header__account-container:hover{
  background-color: #114c68;
  /* background-color: #4F8EAC; */
}

span.chatlio-online-dot {
  background-color: #00aa00;
}
.nat-acct__hq{
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50%;
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  background-color: #B87717;
  float:right;
  margin-top: 14px;
}

.nat-acct__hq>img{
  height: 75%;
  width: 75%;
}

.nat-acct__branch{
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50%;
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;
  background-color: #6FAEB7;
  float:right;
  margin-top: 14px;
}

.nat-acct__branch>img{
  height: 65%;
  width: 65%;
}
.checkout form {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  text-align: left;
}
.login {
  max-width: 380px;
  margin-top: 2rem;
  margin-inline: auto;
}

.checkout input,
.checkout textarea {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  padding: 8px;
  display: block;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 380px;
  border: solid 1px #c6c6c6;
  background-color: #ffffff;
  box-sizing: border-box;
}
.readonlytext {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  padding: 8px;
  display: block;
  border-radius: 4px;
  margin-bottom: 24px;
  width: 380px;
  border: solid 1px #c6c6c6;
  background-color: #e0e0e0;
  box-sizing: border-box;
}

input.cardNumber {
  display: inline-flex;
  margin-left: 8px;
  margin-right: 0px;
  width: 240px;
}
input.expiryDate {
  display: inline-flex;
  /* margin-left: 10px; */
  margin-right: 0px;
  width: 80px;
}
input.cvc {
  display: inline-flex;
  margin-left: 8px;
  margin-right: 0px;
  width: 40px;
}

/* this is needed to display checkbox controls properly */
input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin: 0px 4px 12px 0px;
}

input:focus,
textarea:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

/* !important is needed to get this to apply to the continue and accordion buttons */
button:focus {
  outline: 1px solid #007eff !important;
}

.checkout label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
  color: #4a4a4a;
}

.checkout input.error {
  border-color: red;
}

.readonlytext.error {
  border-color: red;
  margin-bottom: 16px !important;
}

.checkout div.error {
  color: red;
  margin-bottom: 4px;
}

.checkout label > strong {
  color: #f06721;
}

.checkout button {
  width: 380px;
  margin: 20px 0;
  padding: 12px 20px;
  border-style: none;
  border-radius: 5px;
  background-color: #f06721;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}
button.continue {
  margin: 8px 0;
  padding: 12px 20px;
  background-color: #1380b1;
  font-size: 12px;
  width: 180px;
}

button.primaryButton {
  width: 200px;
  margin-right: 20px;
}

button.secondaryButton {
  background-color: #847d7a;
  width: 180px;
  margin-right: 0px;
  padding: 8px 0px;
}

button.primaryButton2 {
  width: 135px;
  margin-right: 0px;
}

button.secondaryButton2 {
  background-color: #847d7a;
  width: 110px;
  margin-left: 20px;
  padding: 8px 0px;
}

.checkout button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.checkout textarea {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  padding: 8px;
  width: 380px;
  height: 90px;
  margin-bottom: 8px;
}

.research textarea {
  height: 166px;
}

.input-feedback {
  color: #f06721;
  margin-top: 0.25rem;
}

.formnote {
  margin-top: -12px;
  margin-bottom: 12px;
  font-size: 12px;
  color: #4a4a4a;
  text-align: left;
}

.formnote strong {
  font-size: 14px;
  color: #f06721;
}

.edit-part {
  width: 400px;
}

.creditcard {
  background-color: red;
  border-style: none;
}

.microform{
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.card-error{
  border: solid 1px red !important;
}

.card-input{
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  padding: 8px;
  display: block;
  border-radius: 4px;
  margin-bottom: 16px;
  height: 15px;
  border: solid 1px #c6c6c6;
  background-color: #ffffff;
}

.microform:nth-child(1){
  margin-right: 15px;
}


/* START BLOCK CLASSES FOR XML */
a.blockLink {
  color: var(--CP-blue);
}
td > a.blockLink {
  display: block;
}
a.blockLink:hover {
  color: black;
}

.headline {
  font-size: large;
  color: #0096db;
}

.shaded {
  font-weight: normal;
  font-size: x-small;
  border-bottom-color: #333333;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-color: #e4eff5;
}

.partnumber {
  font-weight: bold;
  font-size: x-small;
  border-bottom-color: #333333;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-color: #e4eff5;
}

.header {
  text-align: center;
  font-weight: bold;
  font-size: x-small;
  color: white;
}

.cell {
  font-weight: normal;
  font-size: x-small;
  border-bottom-color: #333333;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

table.part {
  border-left-color: #000000;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: #000000;
  border-right-style: solid;
  border-right-width: 1px;
}

.part td {
  padding: 5px;
}

.text {
  font-size: 10pt;
}

.image {
  position: relative;
  float: left;
  margin: 10px;
  text-align: center;
}

.caption {
  font-size: 11pt;
  font-weight: bold;
}

.marker {
  font-size: 12pt;
  color: red;
}

.NLA {
  color: red;
}

.bullet {
  color: #0096db;
}

/* END BLOCK CLASSES FOR XML */

.error {
  color: red;
}

.imagebutton {
  cursor: pointer;
}

td.ruler {
  font-size: small;
  background-color: #e8e8e8;
  width: 1in;
}

#myTable {
  width: 3in;
  height: 2in;
  border-color: Black;
  border-style: solid;
  border-width: 1px;
}

.blocktitle {
  font-size: 14pt;
  font-weight: bold;
  background-color: #cae1ff;
  width: 7in;
}

.tableheader {
  background-color: Black;
  color: White;
  font-weight: bold;
  text-align: center;
}

.parttable {
  font-size: 10pt;
  border-color: Black;
  border-style: solid;
  border-width: 1px;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
  width: 100%;
}

.partTableRow {
  background-color: var(--very-light-blue);
  text-align: left;
  vertical-align: top;
}

.tinted {
  background-color: #cae1ff;
}

.partno {
  font-weight: bold;
  background-color: #cae1ff;
}

.refno {
  background-color: #cae1ff;
}

.genericblocktitle {
  font-size: 14pt;
  font-weight: bold;
  background-color: #cae1ff;
  width: 7in;
}

.oemblocktitle {
  font-size: 14pt;
  font-weight: bold;
  /*background-color:#FFCC00;*/
  background-color: #fffbd0;
  width: 7in;
}

.generictinted {
  background-color: #cae1ff;
}

.genericpartno {
  font-weight: bold;
  background-color: #cae1ff;
}

.oemtinted {
  background-color: #fffbd0;
}

.oempartno {
  font-weight: bold;
  background-color: #fffbd0;
}

.specname {
  font-weight: bold;
}

.specvalue {
  font-style: italic;
}

.verticalSpec,
.specname {
  float: left;
  margin-right: 5px;
}

.verticalSpec,
.specvalue {
  display: block;
}

.note {
  font-size: 8pt;
  line-height: 12px;
}

.footnote {
  font-size: 8pt;
}

.rednote {
  font-weight: bold;
  color: red;
}

.alert {
  color: red;
}

img.part {
  border: 1px solid #e0e0e0;
}

.imageBlock {
  border: none;
  padding: 5px;
  float: left;
  border-width: 1px;
  border-color: #f0f0f0;
  margin: 10px;
  padding: 10px;
}

.imageBlock #filename {
  color: #66ccff;
  font-style: italic;
  text-align: center;
}

.imageBlock .caption {
  text-align: center;
  font-weight: bold;
}

/* clearfix class forces the block to be as high as the tallest image inside */
/* details here: http://swiftthemes.com/2009/12/css-tips-and-hacks/problem-with-height-of-div-containing-floats-and-inline-lists/ */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}

td.top {
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-top-color: black;
  border-right-color: black;
  border-left-color: black;
}

td.cheader {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  background-color: #d1e9d4;
  font-weight: bold;
}

td.citem {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  background-color: #fff;
}

td.mheader {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
  background-color: #d1e9d4;
  font-weight: bold;
}

td.mitem {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
  background-color: #fff;
}

td.color {
  background-color: #d1e9d4;
  text-align: right;
  font-size: small;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

td.unit {
  background-color: #d1e9d4;
  text-align: right;
  font-size: small;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

.gasketcolor {
  background-color: #d1e9d4;
  text-align: right;
  font-size: small;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

.gasketpartno {
  background-color: #d1e9d4;
  font-weight: bold;
  font-size: small;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

.gasketunit {
  background-color: #d1e9d4;
  text-align: right;
  font-size: small;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

.marker {
  font-size: 12pt;
  color: red;
}

/* ABSOLUTE POSITIONING */
/*******************************************/
.flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  -webkit-filter: fliph;
          filter: fliph; /*IE*/
}

.flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -ms-filter: flipv; /*IE*/
  -webkit-filter: flipv;
          filter: flipv; /*IE*/
}

#blocktitleAbs {
  font-size: 12pt;
  font-weight: bold;
  background-color: #cae1ff;
  padding: 2px;
}

.tableheaderAbs {
  background-color: Black;
  color: White;
  font-weight: bold;
  font-size: 8pt;
  text-align: center;
}

.parttableAbs {
  font-size: 8pt;
  border-color: Black;
  border-style: solid;
  border-width: 1px;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 4px;
  line-height: 16px;
}

.genericblocktitleAbs {
  background-color: #cae1ff;
}

.oemblocktitleAbs {
  background-color: #fffbd0;
}

.specsAbs {
  font-size: 8pt;
}

.captionAbs {
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  line-height: 10px;
}

.noteAbs {
  font-size: 10pt;
  line-height: 12pt;
}

.warningAbs {
  font-size: 9pt;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  background-color: #f5d9d9;
  border: 1px solid black;
}

.warningHeaderAbs {
  font-weight: bold;
  background-color: #e24848;
  color: white;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid black;
}

.warningTextAbs {
  margin: 8px;
  text-align: left;
  font-size: 8pt;
  line-height: 8pt;
}

.tipAbs {
  border-color: black;
  border-width: 1px;
  border-style: solid;
  background-color: #fffce9;
}

.tipHeaderAbs {
  font-size: 9pt;
  font-weight: bold;
  text-align: center;
  border-right: 1px solid black;
  line-height: 10px;
  display: table-cell;
  vertical-align: middle;
  background-color: #fdef83;
}

.tipTextAbs {
  padding-top: 0px;
  padding-left: 8px;
  text-align: left;
  font-size: 8pt;
  line-height: 8pt;
  display: table-cell;
  vertical-align: middle;
}

.padlockAbs {
  font-size: 9pt;
  border-color: black;
  border-width: 1px;
  border-style: solid;
}

.padlockHeaderAbs {
  font-weight: bold;
  text-align: center;
  border-right: 1px solid black;
  display: table-cell;
  vertical-align: middle;
  background-color: #ffc36e;
}

.padlockTextAbs {
  color: Black;
  padding-left: 2px;
  font-size: 8pt;
  line-height: 8pt;
  display: table-cell;
  vertical-align: middle;
  background-color: #fff3e1;
}

.bulletAbs {
  font-size: 8pt;
  line-height: 8pt;
}

.lineElement {
  background-color: Black;
}

td.cheaderAbs {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  background-color: #d1e9d4;
  font-weight: bold;
}

td.mheaderAbs {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
  background-color: #d1e9d4;
  font-weight: bold;
}

.rotate90CW {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  /* IE8+ - must be on one line, unfortunately */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=-1.836909530733566e-16, M12=-1, M21=1, M22=-1.836909530733566e-16, SizingMethod='auto expand')";
  filter: progid:DXImageTransform.Microsoft.Matrix( M11=-1.836909530733566e-16, M12=-1, M21=1, M22=-1.836909530733566e-16, SizingMethod='auto expand');
}

.rotate90CCW {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  /* IE8+ - must be on one line, unfortunately */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=3.061515884555943e-16, M12=1, M21=-1, M22=3.061515884555943e-16, SizingMethod='auto expand')";
  /* IE6 and 7 */
  filter: progid:DXImageTransform.Microsoft.Matrix( M11=3.061515884555943e-16, M12=1, M21=-1, M22=3.061515884555943e-16, SizingMethod='auto expand');
}

.blockName {
  font-size: 12pt;
  font-weight: bold;
  padding: 2px;
  padding-left: 8px;
}

/* Commented out as we are not matching printed catalog color-coding conventions in online catalog
.blockName-section1 {
  background-color: #782b90;
  color: #ffffff;
}

.blockName-section2 {
  background-color: #0088b9;
  color: #ffffff;
}

.blockName-section3 {
  background-color: #00ab4e;
  color: #ffffff;
}

.blockName-section4 {
  background-color: #a6ce39;
  color: #ffffff;
}

.blockName-section5 {
  background-color: #fcaf17;
  color: #ffffff;
}

.blockName-section6 {
  background-color: #f47920;
  color: #ffffff;
}

.blockName-section7 {
  background-color: #da2128;
  color: #ffffff;
}

.blockName-sectionOEM {
  background-color: #ffe100;
  color: #000000;
}

.text-section1 {
  background-color: #d8cae3;
}

.text-section2 {
  background-color: #c8dceb;
}

.text-section3 {
  background-color: #d1e9d4;
}

.text-section4 {
  background-color: #eaf3d5;
}

.text-section5 {
  background-color: #ffeccd;
}

.text-section6 {
  background-color: #fee0c8;
}

.text-section7 {
  background-color: #f5d0c1;
}

.text-sectionOEM {
  background-color: #ffe100;
}
*/
.ptable {
  font-size: 8pt;
  border-collapse: collapse;
  line-height: 14pt;
}

.ptable th {
  border: 1px solid black;
  padding: 1px 5px 1px 5px;
  font-family: Roboto, Geneva, Arial, Helvetica, sans-serif;
}

.ptable td {
  border: 1px solid black;
  padding: 1px 5px 1px 5px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.ptable-HeaderRow-section1 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
  text-align: center;
}

.ptable-partNumber-section1 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section2 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section2 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section3 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section3 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section4 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section4 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section5 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section5 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section6 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section6 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section7 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section7 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-sectionOEM {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-sectionOEM {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.dimension {
  font-family: "Roboto", sans-serif;
}
.dimensionEditor .label {
  display: inline-block;
  margin: 4px;
  width: 60px;
}

.dimensionEditor value {
  margin-left: 8px;
  margin-right: 8px;
}

.dimensionEditor input {
  text-align: center;
  width: 80px;
  margin-left: 4px;
  margin-right: 4px;
}

.errorMessage {
  margin-left: 4px;
  color: red;
  font-size: 14px;
  margin-top: 12px;
}
.login .errorMessage {
  margin-bottom: 12px;
}

.error {
  color: red;
}

.dimension {
  font-size: 16px;
}

.doorSides {
  margin-top: 20px;
  margin-left: 4px;
}

.noMagnet {
  margin-top: 20px;
  margin-left: 4px;
}

.dartToDart {
  margin-top: 4px;
  margin-left: 4px;
}

.inverted {
  margin-top: 0px;
  margin-left: 4px;
  margin-bottom: 12px;
}

.gasketProfileList {
  margin: 0px;
  display: flex;
  flex-flow: row wrap;
}

.gasketProfile {
  width: 200px;
  height: 200px;
  margin: 0px 8px 16px 0px;
  cursor: pointer;
}

.gasketProfile p {
  background-color: #c0c0c0;
  margin: 8px 0px;
  color: #000000;
  width: 192px;
  padding: 4px;
}

.gasketImage {
  background-color: white;
  padding: 8px;
}

.gasketProfile:hover,
.customPartsBanner button:hover {
  color: black;
  background-color: var(--highlight-orange);
}

.gasketProfile img {
  margin: 8px;
  max-height: 150px;
  max-width: 150px;
}

.customPartsBanner {
  background-color: #f06721;
  color: white;
  padding: 8px;
}

.text {
  max-width: 800px;
}

.customPartColumn {
  max-width: 400px;
  padding-right: 40px;
  font-size: 14px;
}

.gasketProfiles h2 {
  margin: 20px 0px 0px 0px;
}

.gasketPreview {
  position: absolute;
  /* overflow: scroll; */
  left: 460px;
  top: 143px;
  max-width: 400px;
  width: 90%;
  padding: 0.5em;
  background-color: #648cb3;
}

.smaller {
  font-size: 80%;
}

input[type="radio"] {
  margin-right: 8px;
}

.gasketForm {
  margin-top: -10px;
  padding: 0.5em 0em 1em 1.1em;
  width: 400px;
  /* height: 80vh; */
  /* overflow: hidden; */
  background-color: #f2f2f2;
  border: solid 1px #979797;
}

.price {
  display: inline-block;
  margin-right: 20px;
}

.norefund {
  margin-top: 8px;
  font-size: 14px;
  font-style: italic;
}

.flexContainer {
  display: flex;
  flex-flow: row wrap;
}
.pageHead {
  margin-top: 12px;
  padding-left: 20px;
}
.pageHead > img {
  float: left;
}
.pageHead > h1 {
  padding-top: 10px;
  padding-left: 52px;
}
.pageBody {
  padding-top: 20px;
  padding-left: 20px;
  font-size: 16px;
}
.customItem {
  width: 275px;
  height: 190px;
}
.wireForm {
  position: relative;
  top: -9px;
  padding: 0.5em 0em 1em 1.1em;
  width: 400px;
  /* height: 80vh; */
  /* overflow: hidden; */
  background-color: #f2f2f2;
  border: solid 1px #979797;
}
.wireForm .nudgeDown {
  display: inline-block;
  position: relative;
  top: -25px;
}
.wireForm h2 {
  margin-top: 12px;
  margin-bottom: 4px;
}
.wireSides {
  margin-top: 8px;
  margin-left: 4px;
}
.wireSides > label {
  padding-bottom: 8px;
}
.wireSides div label {
  display: block;
}
.wireForm .wireSides > div {
  margin-bottom: 12px;
  margin-top: 4px;
}
.wireForm .wireSides img {
  height: 64px;
  width: 48px;
}
.wireDetails {
  padding-left: 30px;
}
.wireDetails table {
  border-collapse: collapse;
}
.wireDetails td {
  border: solid 1px #979797;
  padding: 2px 8px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.wireDetails th {
  font-size: 13px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
  padding: 4px;
}
.wireDetails th.hidden,
.wireDetails td.hidden {
  border-style: none;
  background-color: white;
}
.wireDetails .selected td {
  color: white;
  background-color: #0075ff;
}

.accessories {
  margin-top: 20px;
}
.accessories > label {
  display: block;
  padding-bottom: 4px;
}

.voltage {
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 8px;
}

td.left,
th.left {
  text-align: left;
}

.wireIntro {
  width: 400px;
  padding: 20px;
  font-size: 14px;
}
.wireImage {
  width: 288px;
  height: 83px;
  margin-left: 36px;
  margin-top: 20px;
  margin-bottom: 12px;
}
.wireForm .smaller {
  font-size: 80%;
  font-weight: 100;
  font-style: italic;
}
.hints p {
  margin-left: 30px;
  font-style: italic;
  margin-top: 35px;
  width: 400px;
  padding: 20px;
  background-color: beige;
  border: solid 1px #c0c0c0;
}

.heaterCover {
  float: right;
  position: relative;
  left: -70px;
  top: -15px;
}

table td.quantity {
  padding: 0px 0px 0px 12px;
}
table input.quantity {
  text-align: right;
  width: 32px;
  padding: 2px;
  margin: 0px;
  border-style: none;
  font-size: 14px;
}

.norefund h3 {
  margin-bottom: 0px;
}
.norefund ol {
  margin: 0px;
}

.wireDetails h2 {
  margin-bottom: 2px;
}

.wireDetails h2.first {
  margin-top: -10px;
}

img.link {
  border: 1px solid #0098dd;
}

.customItem {
  height: 320px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.customItem h1 {
  font-size: 18px;
  color: #0098dd;
  cursor: pointer;
}

.customItemMenu {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.customItemButton {
  color: white;
  font-size: 12px;
  background-color: #1280b1;
  padding: 8px;
  margin: 8px;
  width: 80px;
}
.customItemButton img {
  float: left;
  width: 26px;
  padding-right: 8px;
}
.customItemButton div {
  text-align: left;
  float: left;
}
.customItemButton a {
  color: white;
}
.maxwidth1200 {
  max-width: 1200px;
}
.customPartColumn table {
  border-collapse: collapse;
}
.customPartColumn th {
  background-color: #404040;
  color: white;
  text-align: left;
  padding: 0px 4px;
  border-style: none;
}
.customPartColumn td {
  text-align: left;
  border-bottom: 1px solid #c0c0c0;
  padding: 0px 4px;
}

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  max-width: 432px;
  border: 1px solid #ccc;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.accordion.active {
  background-color: #ccc;
  color: #000;
}

.error {
  color: red;
}

/* Style the accordion content title */
.accordion__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: #fdfdfd;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

button.accordion {
  color: #444;
  background-color: #eee;
  width: 100%;
  margin: 0px 0px -1px 0px;
  padding: 0px 12px;
  border-radius: 0px;
  border-style: none;
}

.page {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 985px; /* avoid horizontal scrolling on Chrome down to 1024 screen width */
  background-color: #f4f4f4;
}
.metricspage {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 50px;
  min-width: 400px; /* avoid horizontal scrolling on Chrome down to 1024 screen width */
  background-color: #ffffff;
}
.requestSystemMessage {
  font-size: 14px;
  margin-top: 0px;
  margin-left: 20px;
}
.request-menu {
  margin: 4px;
}
.requestEditorColumn {
  float: left;
  margin-right: 20px;
  margin-bottom: 12px;
}
.requestEditor {
  background-color: #e6e6e6;
  border: solid 1px #c0c6ca;
  padding: 8px 12px 4px 12px;
  margin-bottom: 12px;
  clear: both;
}
.requestEditor label {
  font-size: 12px;
  font-weight: bold;
  display: block;
}
.requestEditor input,
.requestEditor select {
  font-size: 14px;
  font-weight: normal;
  color: var(--black);
  padding: 2px;
}
.requestErrorMessage {
  font-size: 16px;
  color: red;
  margin-bottom: 6px;
}
#reqStatusList {
  width: 160px;
}
#reqActionList {
  width: 160px;
}
#reqOwnerList {
  width: 320px;
}
.requestEditor input {
  width: 450px;
}
.requestEditor button {
  padding: 4px 25px;
  margin-bottom: 8px;
  border-style: none;
  border-radius: 5px;
  background-color: var(--reddish-orange);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}
.refreshButton {
  padding: 4px 25px;
  margin-left: 20px;
  border-style: none;
  border-radius: 5px;
  background-color: var(--reddish-orange);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}

.smalltext {
  font-size: 65%;
  font-weight: 300;
}
.menu-item {
  margin: 8px;
  color: var(--CP-blue);
  cursor: pointer;
}
.menu-item a {
  display: block;
}
.menu-item:hover {
  color: black;
  background-color: var(--highlight-orange);
}
.requestCard {
  box-shadow: 0 3px 7px -5px rgba(0, 0, 0, 1);
  border: solid 1px #c0c6ca;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 12px;
  float: left;
  cursor: pointer;
}
.requestCardNormal {
  min-width: 95%;
}
.requestCardPopup {
  min-width: 400px;
  max-width: 50%;
  cursor: auto;
}
.requestEditorMenuOptions {
  padding-top: 12px;
  margin-left: 20px;
  float: left;
}
.requestNew {
  background-color: #d7edf5;
}
.requestAssigned {
  background-color: #f5ecc6;
}
.requestActive {
  background-color: #defbe3;
}
.requestWaiting {
  background-color: #ffd9d9;
}
.requestDone {
  background-color: #ececec;
}

.requestDetail {
  margin: 8px 8px 12px 8px;
  background-color: var(--paper-grey);
  max-height: 95vh;
  overflow-y: auto;
}
.requestDetail h1 {
  margin: 0px 0px 4px 0px;
}
.requestOwner {
  margin-top: 4px;
  margin-right: 4px;
  float: right;
}
.requestOwner img {
  float: right;
}
.miniOwner {
  margin-top: 2px;
  margin-left: 2px;
  float: left;
  width: 100%;
}
.miniOwner img {
  float: left;
  display: block;
}
.miniOwner .ownerName {
  margin-top: 2px;
  margin-left: 4px;
  text-align: left;
  font-size: 14px;
  float: left;
}
.ownerName {
  float: right;
  text-align: right;
  font-size: 16px;
  line-height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  color: var(--black);
  font-weight: bold;
}
.ownerBranch {
  font-size: 12px;
  color: var(--black);
  font-weight: normal;
}
.requestList {
  width: 19%;
  margin-right: 12px;
  float: left;
}
.requestList h2 {
  color: #808080;
}
.requestList h3 {
  display: inline;
}
.requestInfo h4 {
  font-size: .75rem;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 200;
  color: var(--greyish-brown);
  margin: 0px;
}

.requestInfo {
  padding-top: 8px;
  font-size: 12px;
  color: var(--greyish-brown);
  clear: both;
  float: left;
}
.requestNote {
  clear: both;
  padding-top: 6px;
  font-size: 11px;
  color: var(--black);
}
.requestWaitReason {
  color: red;
  font-size: 13px;
}
.requestTimestamps {
  padding-top: 8px;
  padding-bottom: 0px;
  width: 110px;
  float: right;
}
.requestTimestamp {
  margin: 0px;
  clear: both;
  vertical-align: bottom;
  font-size: 10px;
  font-weight: bold;
  color: var(--greyish-brown);
}
.subtitle {
  font-size: 12px;
  color: #808080;
  margin-left: 20px;
}

table.subtleTable {
  border: solid 1px #c0c6ca;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.subtleTable thead,
table.subtleTable th {
  background-color: #127fb1;
}
table.subtleTable th {
  font-size: 14px;
  color: white;
  font-weight: normal;
  border: solid 1px #d7d9dd;
  padding: 4px;
}
table.subtleTable tbody td {
  font-size: 13px;
  color: var(--greyish-brown);
  border: solid 1px #d7d9dd;
  padding: 4px;
}
table.subtleTable tr:nth-child(odd) {
  background: #ffffff;
}
table.subtleTable tr:nth-child(even) {
  background: #eeeeee;
}

table.blueTable {
  border: 1px solid #1c6ea4;
  background-color: #eeeeee;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.blueTable td,
table.blueTable th {
  border: 1px solid #aaaaaa;
  padding: 4px 8px;
}
table.blueTable tbody td {
  font-size: 13px;
}
table.blueTable tr:nth-child(even) {
  background: #d0e4f5;
}
table.blueTable thead {
  background: #1c6ea4;
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  border-bottom: 2px solid #444444;
}
table.blueTable thead th {
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  border-left: 2px solid #d0e4f5;
}
table.blueTable thead th:first-child {
  border-left: none;
}
a {
  color: #1380b1;
}
.requestId {
  font-size: 16px;
}
.requestLink {
  font-size: 14px;
}
label {
  margin-right: 8px;
}
input,
select {
  margin-right: 20px;
}
form {
  margin-top: 8px;
  margin-bottom: 8px;
}
.errorbox {
  width: 100%;
  margin: 50px;
  padding: 50px;
  background-color: red;
  color: white;
  font-size: 36px;
}
.summaryMetrics {
  margin-top: -10px;
  float: right;
  text-align: right;
}
.summaryMetrics table {
  border: 1px solid #3d9665;
  border-collapse: collapse;
}
.summaryMetrics td {
  padding: 3px;
}
.summaryMetrics tr:nth-child(odd) {
  background: #3d9665;
  color: #ffffff;
}
.summaryMetrics tr:nth-child(even) {
  color: #3d9665;
}
.halfWidth {
  width: 49%;
  float: left;
}
.amount {
  text-align: right;
}
.columnHeading {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #fff;
}
.columnHeading a {
  text-decoration: none;
  cursor: pointer;
}
.boxedText {
  padding: 1px 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #aaaaaa;
  cursor: pointer;
}
.daterangeOptions {
  margin: 8px 20px;
}
.red {
  color: red;
}
.order {
  color: white !important;
  background-color: #3d9665;
  font-style: italic;
}
.quote {
  color: #3d9665 !important;
  font-style: italic;
}
.rma {
  color: red !important;
  font-style: italic;
}
.subtleTable button {
  border-color: #afafaf;
  border-style: solid;
  border-width: 1px;
  color: #4a4a4a;
  font-size: 12px;
}
.subtleTable button:hover {
  color: black;
  background-color: var(--highlight-orange);
}
.clickable a {
  display: block;
  width: 100%;
  text-decoration: none;
}
.requestType:hover,
.clickable:hover {
  background-color: var(--highlight-orange) !important;
}
.requestType-arrow,
.requestType-overlay,
.requestType-content {
  background-color: var(--highlight-orange) !important;
}

