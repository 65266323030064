.dimension {
  font-family: "Roboto", sans-serif;
}
.dimensionEditor .label {
  display: inline-block;
  margin: 4px;
  width: 60px;
}

.dimensionEditor value {
  margin-left: 8px;
  margin-right: 8px;
}

.dimensionEditor input {
  text-align: center;
  width: 80px;
  margin-left: 4px;
  margin-right: 4px;
}

.errorMessage {
  margin-left: 4px;
  color: red;
  font-size: 14px;
  margin-top: 12px;
}
.login .errorMessage {
  margin-bottom: 12px;
}

.error {
  color: red;
}

.dimension {
  font-size: 16px;
}

.doorSides {
  margin-top: 20px;
  margin-left: 4px;
}

.noMagnet {
  margin-top: 20px;
  margin-left: 4px;
}

.dartToDart {
  margin-top: 4px;
  margin-left: 4px;
}

.inverted {
  margin-top: 0px;
  margin-left: 4px;
  margin-bottom: 12px;
}

.gasketProfileList {
  margin: 0px;
  display: flex;
  flex-flow: row wrap;
}

.gasketProfile {
  width: 200px;
  height: 200px;
  margin: 0px 8px 16px 0px;
  cursor: pointer;
}

.gasketProfile p {
  background-color: #c0c0c0;
  margin: 8px 0px;
  color: #000000;
  width: 192px;
  padding: 4px;
}

.gasketImage {
  background-color: white;
  padding: 8px;
}

.gasketProfile:hover,
.customPartsBanner button:hover {
  color: black;
  background-color: var(--highlight-orange);
}

.gasketProfile img {
  margin: 8px;
  max-height: 150px;
  max-width: 150px;
}

.customPartsBanner {
  background-color: #f06721;
  color: white;
  padding: 8px;
}

.text {
  max-width: 800px;
}

.customPartColumn {
  max-width: 400px;
  padding-right: 40px;
  font-size: 14px;
}

.gasketProfiles h2 {
  margin: 20px 0px 0px 0px;
}

.gasketPreview {
  position: absolute;
  /* overflow: scroll; */
  left: 460px;
  top: 143px;
  max-width: 400px;
  width: 90%;
  padding: 0.5em;
  background-color: #648cb3;
}

.smaller {
  font-size: 80%;
}

input[type="radio"] {
  margin-right: 8px;
}

.gasketForm {
  margin-top: -10px;
  padding: 0.5em 0em 1em 1.1em;
  width: 400px;
  /* height: 80vh; */
  /* overflow: hidden; */
  background-color: #f2f2f2;
  border: solid 1px #979797;
}

.price {
  display: inline-block;
  margin-right: 20px;
}

.norefund {
  margin-top: 8px;
  font-size: 14px;
  font-style: italic;
}

.flexContainer {
  display: flex;
  flex-flow: row wrap;
}
.pageHead {
  margin-top: 12px;
  padding-left: 20px;
}
.pageHead > img {
  float: left;
}
.pageHead > h1 {
  padding-top: 10px;
  padding-left: 52px;
}
.pageBody {
  padding-top: 20px;
  padding-left: 20px;
  font-size: 16px;
}
.customItem {
  width: 275px;
  height: 190px;
}
.wireForm {
  position: relative;
  top: -9px;
  padding: 0.5em 0em 1em 1.1em;
  width: 400px;
  /* height: 80vh; */
  /* overflow: hidden; */
  background-color: #f2f2f2;
  border: solid 1px #979797;
}
.wireForm .nudgeDown {
  display: inline-block;
  position: relative;
  top: -25px;
}
.wireForm h2 {
  margin-top: 12px;
  margin-bottom: 4px;
}
.wireSides {
  margin-top: 8px;
  margin-left: 4px;
}
.wireSides > label {
  padding-bottom: 8px;
}
.wireSides div label {
  display: block;
}
.wireForm .wireSides > div {
  margin-bottom: 12px;
  margin-top: 4px;
}
.wireForm .wireSides img {
  height: 64px;
  width: 48px;
}
.wireDetails {
  padding-left: 30px;
}
.wireDetails table {
  border-collapse: collapse;
}
.wireDetails td {
  border: solid 1px #979797;
  padding: 2px 8px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.wireDetails th {
  font-size: 13px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
  padding: 4px;
}
.wireDetails th.hidden,
.wireDetails td.hidden {
  border-style: none;
  background-color: white;
}
.wireDetails .selected td {
  color: white;
  background-color: #0075ff;
}

.accessories {
  margin-top: 20px;
}
.accessories > label {
  display: block;
  padding-bottom: 4px;
}

.voltage {
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 8px;
}

td.left,
th.left {
  text-align: left;
}

.wireIntro {
  width: 400px;
  padding: 20px;
  font-size: 14px;
}
.wireImage {
  width: 288px;
  height: 83px;
  margin-left: 36px;
  margin-top: 20px;
  margin-bottom: 12px;
}
.wireForm .smaller {
  font-size: 80%;
  font-weight: 100;
  font-style: italic;
}
.hints p {
  margin-left: 30px;
  font-style: italic;
  margin-top: 35px;
  width: 400px;
  padding: 20px;
  background-color: beige;
  border: solid 1px #c0c0c0;
}

.heaterCover {
  float: right;
  position: relative;
  left: -70px;
  top: -15px;
}

table td.quantity {
  padding: 0px 0px 0px 12px;
}
table input.quantity {
  text-align: right;
  width: 32px;
  padding: 2px;
  margin: 0px;
  border-style: none;
  font-size: 14px;
}

.norefund h3 {
  margin-bottom: 0px;
}
.norefund ol {
  margin: 0px;
}

.wireDetails h2 {
  margin-bottom: 2px;
}

.wireDetails h2.first {
  margin-top: -10px;
}

img.link {
  border: 1px solid #0098dd;
}

.customItem {
  height: 320px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.customItem h1 {
  font-size: 18px;
  color: #0098dd;
  cursor: pointer;
}

.customItemMenu {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.customItemButton {
  color: white;
  font-size: 12px;
  background-color: #1280b1;
  padding: 8px;
  margin: 8px;
  width: 80px;
}
.customItemButton img {
  float: left;
  width: 26px;
  padding-right: 8px;
}
.customItemButton div {
  text-align: left;
  float: left;
}
.customItemButton a {
  color: white;
}
.maxwidth1200 {
  max-width: 1200px;
}
.customPartColumn table {
  border-collapse: collapse;
}
.customPartColumn th {
  background-color: #404040;
  color: white;
  text-align: left;
  padding: 0px 4px;
  border-style: none;
}
.customPartColumn td {
  text-align: left;
  border-bottom: 1px solid #c0c0c0;
  padding: 0px 4px;
}
