.page {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 985px; /* avoid horizontal scrolling on Chrome down to 1024 screen width */
  background-color: #f4f4f4;
}
.metricspage {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 50px;
  min-width: 400px; /* avoid horizontal scrolling on Chrome down to 1024 screen width */
  background-color: #ffffff;
}
.requestSystemMessage {
  font-size: 14px;
  margin-top: 0px;
  margin-left: 20px;
}
.request-menu {
  margin: 4px;
}
.requestEditorColumn {
  float: left;
  margin-right: 20px;
  margin-bottom: 12px;
}
.requestEditor {
  background-color: #e6e6e6;
  border: solid 1px #c0c6ca;
  padding: 8px 12px 4px 12px;
  margin-bottom: 12px;
  clear: both;
}
.requestEditor label {
  font-size: 12px;
  font-weight: bold;
  display: block;
}
.requestEditor input,
.requestEditor select {
  font-size: 14px;
  font-weight: normal;
  color: var(--black);
  padding: 2px;
}
.requestErrorMessage {
  font-size: 16px;
  color: red;
  margin-bottom: 6px;
}
#reqStatusList {
  width: 160px;
}
#reqActionList {
  width: 160px;
}
#reqOwnerList {
  width: 320px;
}
.requestEditor input {
  width: 450px;
}
.requestEditor button {
  padding: 4px 25px;
  margin-bottom: 8px;
  border-style: none;
  border-radius: 5px;
  background-color: var(--reddish-orange);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}
.refreshButton {
  padding: 4px 25px;
  margin-left: 20px;
  border-style: none;
  border-radius: 5px;
  background-color: var(--reddish-orange);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}

.smalltext {
  font-size: 65%;
  font-weight: 300;
}
.menu-item {
  margin: 8px;
  color: var(--CP-blue);
  cursor: pointer;
}
.menu-item a {
  display: block;
}
.menu-item:hover {
  color: black;
  background-color: var(--highlight-orange);
}
.requestCard {
  box-shadow: 0 3px 7px -5px rgba(0, 0, 0, 1);
  border: solid 1px #c0c6ca;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 12px;
  float: left;
  cursor: pointer;
}
.requestCardNormal {
  min-width: 95%;
}
.requestCardPopup {
  min-width: 400px;
  max-width: 50%;
  cursor: auto;
}
.requestEditorMenuOptions {
  padding-top: 12px;
  margin-left: 20px;
  float: left;
}
.requestNew {
  background-color: #d7edf5;
}
.requestAssigned {
  background-color: #f5ecc6;
}
.requestActive {
  background-color: #defbe3;
}
.requestWaiting {
  background-color: #ffd9d9;
}
.requestDone {
  background-color: #ececec;
}

.requestDetail {
  margin: 8px 8px 12px 8px;
  background-color: var(--paper-grey);
  max-height: 95vh;
  overflow-y: auto;
}
.requestDetail h1 {
  margin: 0px 0px 4px 0px;
}
.requestOwner {
  margin-top: 4px;
  margin-right: 4px;
  float: right;
}
.requestOwner img {
  float: right;
}
.miniOwner {
  margin-top: 2px;
  margin-left: 2px;
  float: left;
  width: 100%;
}
.miniOwner img {
  float: left;
  display: block;
}
.miniOwner .ownerName {
  margin-top: 2px;
  margin-left: 4px;
  text-align: left;
  font-size: 14px;
  float: left;
}
.ownerName {
  float: right;
  text-align: right;
  font-size: 16px;
  line-height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  color: var(--black);
  font-weight: bold;
}
.ownerBranch {
  font-size: 12px;
  color: var(--black);
  font-weight: normal;
}
.requestList {
  width: 19%;
  margin-right: 12px;
  float: left;
}
.requestList h2 {
  color: #808080;
}
.requestList h3 {
  display: inline;
}
.requestInfo h4 {
  font-size: .75rem;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 200;
  color: var(--greyish-brown);
  margin: 0px;
}

.requestInfo {
  padding-top: 8px;
  font-size: 12px;
  color: var(--greyish-brown);
  clear: both;
  float: left;
}
.requestNote {
  clear: both;
  padding-top: 6px;
  font-size: 11px;
  color: var(--black);
}
.requestWaitReason {
  color: red;
  font-size: 13px;
}
.requestTimestamps {
  padding-top: 8px;
  padding-bottom: 0px;
  width: 110px;
  float: right;
}
.requestTimestamp {
  margin: 0px;
  clear: both;
  vertical-align: bottom;
  font-size: 10px;
  font-weight: bold;
  color: var(--greyish-brown);
}
.subtitle {
  font-size: 12px;
  color: #808080;
  margin-left: 20px;
}

table.subtleTable {
  border: solid 1px #c0c6ca;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.subtleTable thead,
table.subtleTable th {
  background-color: #127fb1;
}
table.subtleTable th {
  font-size: 14px;
  color: white;
  font-weight: normal;
  border: solid 1px #d7d9dd;
  padding: 4px;
}
table.subtleTable tbody td {
  font-size: 13px;
  color: var(--greyish-brown);
  border: solid 1px #d7d9dd;
  padding: 4px;
}
table.subtleTable tr:nth-child(odd) {
  background: #ffffff;
}
table.subtleTable tr:nth-child(even) {
  background: #eeeeee;
}

table.blueTable {
  border: 1px solid #1c6ea4;
  background-color: #eeeeee;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.blueTable td,
table.blueTable th {
  border: 1px solid #aaaaaa;
  padding: 4px 8px;
}
table.blueTable tbody td {
  font-size: 13px;
}
table.blueTable tr:nth-child(even) {
  background: #d0e4f5;
}
table.blueTable thead {
  background: #1c6ea4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  border-bottom: 2px solid #444444;
}
table.blueTable thead th {
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  border-left: 2px solid #d0e4f5;
}
table.blueTable thead th:first-child {
  border-left: none;
}
a {
  color: #1380b1;
}
.requestId {
  font-size: 16px;
}
.requestLink {
  font-size: 14px;
}
label {
  margin-right: 8px;
}
input,
select {
  margin-right: 20px;
}
form {
  margin-top: 8px;
  margin-bottom: 8px;
}
.errorbox {
  width: 100%;
  margin: 50px;
  padding: 50px;
  background-color: red;
  color: white;
  font-size: 36px;
}
.summaryMetrics {
  margin-top: -10px;
  float: right;
  text-align: right;
}
.summaryMetrics table {
  border: 1px solid #3d9665;
  border-collapse: collapse;
}
.summaryMetrics td {
  padding: 3px;
}
.summaryMetrics tr:nth-child(odd) {
  background: #3d9665;
  color: #ffffff;
}
.summaryMetrics tr:nth-child(even) {
  color: #3d9665;
}
.halfWidth {
  width: 49%;
  float: left;
}
.amount {
  text-align: right;
}
.columnHeading {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #fff;
}
.columnHeading a {
  text-decoration: none;
  cursor: pointer;
}
.boxedText {
  padding: 1px 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #aaaaaa;
  cursor: pointer;
}
.daterangeOptions {
  margin: 8px 20px;
}
.red {
  color: red;
}
.order {
  color: white !important;
  background-color: #3d9665;
  font-style: italic;
}
.quote {
  color: #3d9665 !important;
  font-style: italic;
}
.rma {
  color: red !important;
  font-style: italic;
}
.subtleTable button {
  border-color: #afafaf;
  border-style: solid;
  border-width: 1px;
  color: #4a4a4a;
  font-size: 12px;
}
.subtleTable button:hover {
  color: black;
  background-color: var(--highlight-orange);
}
.clickable a {
  display: block;
  width: 100%;
  text-decoration: none;
}
.requestType:hover,
.clickable:hover {
  background-color: var(--highlight-orange) !important;
}
.requestType-arrow,
.requestType-overlay,
.requestType-content {
  background-color: var(--highlight-orange) !important;
}
