/* START BLOCK CLASSES FOR XML */
a.blockLink {
  color: var(--CP-blue);
}
td > a.blockLink {
  display: block;
}
a.blockLink:hover {
  color: black;
}

.headline {
  font-size: large;
  color: #0096db;
}

.shaded {
  font-weight: normal;
  font-size: x-small;
  border-bottom-color: #333333;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-color: #e4eff5;
}

.partnumber {
  font-weight: bold;
  font-size: x-small;
  border-bottom-color: #333333;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-color: #e4eff5;
}

.header {
  text-align: center;
  font-weight: bold;
  font-size: x-small;
  color: white;
}

.cell {
  font-weight: normal;
  font-size: x-small;
  border-bottom-color: #333333;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

table.part {
  border-left-color: #000000;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: #000000;
  border-right-style: solid;
  border-right-width: 1px;
}

.part td {
  padding: 5px;
}

.text {
  font-size: 10pt;
}

.image {
  position: relative;
  float: left;
  margin: 10px;
  text-align: center;
}

.caption {
  font-size: 11pt;
  font-weight: bold;
}

.marker {
  font-size: 12pt;
  color: red;
}

.NLA {
  color: red;
}

.bullet {
  color: #0096db;
}

/* END BLOCK CLASSES FOR XML */

.error {
  color: red;
}

.imagebutton {
  cursor: pointer;
}

td.ruler {
  font-size: small;
  background-color: #e8e8e8;
  width: 1in;
}

#myTable {
  width: 3in;
  height: 2in;
  border-color: Black;
  border-style: solid;
  border-width: 1px;
}

.blocktitle {
  font-size: 14pt;
  font-weight: bold;
  background-color: #cae1ff;
  width: 7in;
}

.tableheader {
  background-color: Black;
  color: White;
  font-weight: bold;
  text-align: center;
}

.parttable {
  font-size: 10pt;
  border-color: Black;
  border-style: solid;
  border-width: 1px;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
  width: 100%;
}

.partTableRow {
  background-color: var(--very-light-blue);
  text-align: left;
  vertical-align: top;
}

.tinted {
  background-color: #cae1ff;
}

.partno {
  font-weight: bold;
  background-color: #cae1ff;
}

.refno {
  background-color: #cae1ff;
}

.genericblocktitle {
  font-size: 14pt;
  font-weight: bold;
  background-color: #cae1ff;
  width: 7in;
}

.oemblocktitle {
  font-size: 14pt;
  font-weight: bold;
  /*background-color:#FFCC00;*/
  background-color: #fffbd0;
  width: 7in;
}

.generictinted {
  background-color: #cae1ff;
}

.genericpartno {
  font-weight: bold;
  background-color: #cae1ff;
}

.oemtinted {
  background-color: #fffbd0;
}

.oempartno {
  font-weight: bold;
  background-color: #fffbd0;
}

.specname {
  font-weight: bold;
}

.specvalue {
  font-style: italic;
}

.verticalSpec,
.specname {
  float: left;
  margin-right: 5px;
}

.verticalSpec,
.specvalue {
  display: block;
}

.note {
  font-size: 8pt;
  line-height: 12px;
}

.footnote {
  font-size: 8pt;
}

.rednote {
  font-weight: bold;
  color: red;
}

.alert {
  color: red;
}

img.part {
  border: 1px solid #e0e0e0;
}

.imageBlock {
  border: none;
  padding: 5px;
  float: left;
  border-width: 1px;
  border-color: #f0f0f0;
  margin: 10px;
  padding: 10px;
}

.imageBlock #filename {
  color: #66ccff;
  font-style: italic;
  text-align: center;
}

.imageBlock .caption {
  text-align: center;
  font-weight: bold;
}

/* clearfix class forces the block to be as high as the tallest image inside */
/* details here: http://swiftthemes.com/2009/12/css-tips-and-hacks/problem-with-height-of-div-containing-floats-and-inline-lists/ */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}

td.top {
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-top-color: black;
  border-right-color: black;
  border-left-color: black;
}

td.cheader {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  background-color: #d1e9d4;
  font-weight: bold;
}

td.citem {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  background-color: #fff;
}

td.mheader {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
  background-color: #d1e9d4;
  font-weight: bold;
}

td.mitem {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
  background-color: #fff;
}

td.color {
  background-color: #d1e9d4;
  text-align: right;
  font-size: small;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

td.unit {
  background-color: #d1e9d4;
  text-align: right;
  font-size: small;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

.gasketcolor {
  background-color: #d1e9d4;
  text-align: right;
  font-size: small;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

.gasketpartno {
  background-color: #d1e9d4;
  font-weight: bold;
  font-size: small;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

.gasketunit {
  background-color: #d1e9d4;
  text-align: right;
  font-size: small;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
}

.marker {
  font-size: 12pt;
  color: red;
}

/* ABSOLUTE POSITIONING */
/*******************************************/
.flip-horizontal {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
}

.flip-vertical {
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
  -ms-filter: flipv; /*IE*/
  filter: flipv; /*IE*/
}

#blocktitleAbs {
  font-size: 12pt;
  font-weight: bold;
  background-color: #cae1ff;
  padding: 2px;
}

.tableheaderAbs {
  background-color: Black;
  color: White;
  font-weight: bold;
  font-size: 8pt;
  text-align: center;
}

.parttableAbs {
  font-size: 8pt;
  border-color: Black;
  border-style: solid;
  border-width: 1px;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 4px;
  line-height: 16px;
}

.genericblocktitleAbs {
  background-color: #cae1ff;
}

.oemblocktitleAbs {
  background-color: #fffbd0;
}

.specsAbs {
  font-size: 8pt;
}

.captionAbs {
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  line-height: 10px;
}

.noteAbs {
  font-size: 10pt;
  line-height: 12pt;
}

.warningAbs {
  font-size: 9pt;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  background-color: #f5d9d9;
  border: 1px solid black;
}

.warningHeaderAbs {
  font-weight: bold;
  background-color: #e24848;
  color: white;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid black;
}

.warningTextAbs {
  margin: 8px;
  text-align: left;
  font-size: 8pt;
  line-height: 8pt;
}

.tipAbs {
  border-color: black;
  border-width: 1px;
  border-style: solid;
  background-color: #fffce9;
}

.tipHeaderAbs {
  font-size: 9pt;
  font-weight: bold;
  text-align: center;
  border-right: 1px solid black;
  line-height: 10px;
  display: table-cell;
  vertical-align: middle;
  background-color: #fdef83;
}

.tipTextAbs {
  padding-top: 0px;
  padding-left: 8px;
  text-align: left;
  font-size: 8pt;
  line-height: 8pt;
  display: table-cell;
  vertical-align: middle;
}

.padlockAbs {
  font-size: 9pt;
  border-color: black;
  border-width: 1px;
  border-style: solid;
}

.padlockHeaderAbs {
  font-weight: bold;
  text-align: center;
  border-right: 1px solid black;
  display: table-cell;
  vertical-align: middle;
  background-color: #ffc36e;
}

.padlockTextAbs {
  color: Black;
  padding-left: 2px;
  font-size: 8pt;
  line-height: 8pt;
  display: table-cell;
  vertical-align: middle;
  background-color: #fff3e1;
}

.bulletAbs {
  font-size: 8pt;
  line-height: 8pt;
}

.lineElement {
  background-color: Black;
}

td.cheaderAbs {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  background-color: #d1e9d4;
  font-weight: bold;
}

td.mheaderAbs {
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: black;
  border-left-color: black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
  background-color: #d1e9d4;
  font-weight: bold;
}

.rotate90CW {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  /* IE8+ - must be on one line, unfortunately */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=-1.836909530733566e-16, M12=-1, M21=1, M22=-1.836909530733566e-16, SizingMethod='auto expand')";
  filter: progid:DXImageTransform.Microsoft.Matrix( M11=-1.836909530733566e-16, M12=-1, M21=1, M22=-1.836909530733566e-16, SizingMethod='auto expand');
}

.rotate90CCW {
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  /* IE8+ - must be on one line, unfortunately */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=3.061515884555943e-16, M12=1, M21=-1, M22=3.061515884555943e-16, SizingMethod='auto expand')";
  /* IE6 and 7 */
  filter: progid:DXImageTransform.Microsoft.Matrix( M11=3.061515884555943e-16, M12=1, M21=-1, M22=3.061515884555943e-16, SizingMethod='auto expand');
}

.blockName {
  font-size: 12pt;
  font-weight: bold;
  padding: 2px;
  padding-left: 8px;
}

/* Commented out as we are not matching printed catalog color-coding conventions in online catalog
.blockName-section1 {
  background-color: #782b90;
  color: #ffffff;
}

.blockName-section2 {
  background-color: #0088b9;
  color: #ffffff;
}

.blockName-section3 {
  background-color: #00ab4e;
  color: #ffffff;
}

.blockName-section4 {
  background-color: #a6ce39;
  color: #ffffff;
}

.blockName-section5 {
  background-color: #fcaf17;
  color: #ffffff;
}

.blockName-section6 {
  background-color: #f47920;
  color: #ffffff;
}

.blockName-section7 {
  background-color: #da2128;
  color: #ffffff;
}

.blockName-sectionOEM {
  background-color: #ffe100;
  color: #000000;
}

.text-section1 {
  background-color: #d8cae3;
}

.text-section2 {
  background-color: #c8dceb;
}

.text-section3 {
  background-color: #d1e9d4;
}

.text-section4 {
  background-color: #eaf3d5;
}

.text-section5 {
  background-color: #ffeccd;
}

.text-section6 {
  background-color: #fee0c8;
}

.text-section7 {
  background-color: #f5d0c1;
}

.text-sectionOEM {
  background-color: #ffe100;
}
*/
.ptable {
  font-size: 8pt;
  border-collapse: collapse;
  line-height: 14pt;
}

.ptable th {
  border: 1px solid black;
  padding: 1px 5px 1px 5px;
  font-family: Roboto, Geneva, Arial, Helvetica, sans-serif;
}

.ptable td {
  border: 1px solid black;
  padding: 1px 5px 1px 5px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.ptable-HeaderRow-section1 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
  text-align: center;
}

.ptable-partNumber-section1 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section2 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section2 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section3 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section3 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section4 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section4 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section5 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section5 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section6 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section6 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-section7 {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-section7 {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}

.ptable-HeaderRow-sectionOEM {
  background-color: var(--dark-blue);
  color: white;
  font-weight: bold;
}

.ptable-partNumber-sectionOEM {
  background-color: var(--very-light-blue);
  color: #000;
  font-weight: bold;
}
