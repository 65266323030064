.checkout form {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  text-align: left;
}
.login {
  max-width: 380px;
  margin-top: 2rem;
  margin-inline: auto;
}

.checkout input,
.checkout textarea {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  padding: 8px;
  display: block;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 380px;
  border: solid 1px #c6c6c6;
  background-color: #ffffff;
  box-sizing: border-box;
}
.readonlytext {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  padding: 8px;
  display: block;
  border-radius: 4px;
  margin-bottom: 24px;
  width: 380px;
  border: solid 1px #c6c6c6;
  background-color: #e0e0e0;
  box-sizing: border-box;
}

input.cardNumber {
  display: inline-flex;
  margin-left: 8px;
  margin-right: 0px;
  width: 240px;
}
input.expiryDate {
  display: inline-flex;
  /* margin-left: 10px; */
  margin-right: 0px;
  width: 80px;
}
input.cvc {
  display: inline-flex;
  margin-left: 8px;
  margin-right: 0px;
  width: 40px;
}

/* this is needed to display checkbox controls properly */
input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin: 0px 4px 12px 0px;
}

input:focus,
textarea:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

/* !important is needed to get this to apply to the continue and accordion buttons */
button:focus {
  outline: 1px solid #007eff !important;
}

.checkout label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
  color: #4a4a4a;
}

.checkout input.error {
  border-color: red;
}

.readonlytext.error {
  border-color: red;
  margin-bottom: 16px !important;
}

.checkout div.error {
  color: red;
  margin-bottom: 4px;
}

.checkout label > strong {
  color: #f06721;
}

.checkout button {
  width: 380px;
  margin: 20px 0;
  padding: 12px 20px;
  border-style: none;
  border-radius: 5px;
  background-color: #f06721;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}
button.continue {
  margin: 8px 0;
  padding: 12px 20px;
  background-color: #1380b1;
  font-size: 12px;
  width: 180px;
}

button.primaryButton {
  width: 200px;
  margin-right: 20px;
}

button.secondaryButton {
  background-color: #847d7a;
  width: 180px;
  margin-right: 0px;
  padding: 8px 0px;
}

button.primaryButton2 {
  width: 135px;
  margin-right: 0px;
}

button.secondaryButton2 {
  background-color: #847d7a;
  width: 110px;
  margin-left: 20px;
  padding: 8px 0px;
}

.checkout button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.checkout textarea {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  padding: 8px;
  width: 380px;
  height: 90px;
  margin-bottom: 8px;
}

.research textarea {
  height: 166px;
}

.input-feedback {
  color: #f06721;
  margin-top: 0.25rem;
}

.formnote {
  margin-top: -12px;
  margin-bottom: 12px;
  font-size: 12px;
  color: #4a4a4a;
  text-align: left;
}

.formnote strong {
  font-size: 14px;
  color: #f06721;
}

.edit-part {
  width: 400px;
}

.creditcard {
  background-color: red;
  border-style: none;
}

.microform{
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 10px;
}

.card-error{
  border: solid 1px red !important;
}

.card-input{
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  padding: 8px;
  display: block;
  border-radius: 4px;
  margin-bottom: 16px;
  height: 15px;
  border: solid 1px #c6c6c6;
  background-color: #ffffff;
}

.microform:nth-child(1){
  margin-right: 15px;
}

